var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.countMony > 0
    ? _c("el-tooltip", { attrs: { placement: "top" } }, [
        _c("i", { staticClass: "el-icon-warning-outline" }),
        _c(
          "div",
          {
            staticClass: "commodity_info",
            attrs: { slot: "content" },
            slot: "content",
          },
          [
            _c("div", [
              _vm._v(
                "平台补贴-￥" + _vm._s(_vm.sumPlatformDiscountMoney || "0.00")
              ),
            ]),
            _c("div", [
              _vm._v("商户优惠-￥" + _vm._s(_vm.sumDiscountMoney || "0.00")),
            ]),
            _c("div", [
              _vm._v(
                "平台代金券-￥" + _vm._s(_vm.sumVoucherDiscountMoney || "0.00")
              ),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }