<template>
  <el-tooltip placement="top" v-if="countMony > 0">
    <i class="el-icon-warning-outline"></i>
    <div class="commodity_info" slot="content">
      <div>平台补贴-￥{{ sumPlatformDiscountMoney || "0.00" }}</div>
      <div>商户优惠-￥{{ sumDiscountMoney || "0.00" }}</div>
      <div>平台代金券-￥{{ sumVoucherDiscountMoney || "0.00" }}</div>
    </div>
  </el-tooltip>
</template>
<script>
export default {
  name: "orderDiscountTooltip",
  props: {
    sumDiscountMoney: {
      type: String,
      default: "0.00",
    },
    sumPlatformDiscountMoney: {
      type: String,
      default: "0.00",
    },
    sumVoucherDiscountMoney: {
      type: String,
      default: "0.00",
    },
  },
  computed: {
    countMony() {
      return (
        parseFloat(this.sumDiscountMoney || 0) +
        parseFloat(this.sumPlatformDiscountMoney || 0) +
        parseFloat(this.sumVoucherDiscountMoney || 0)
      );
    },
  },
};
</script>
<style scoped lang="scss"></style>
