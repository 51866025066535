const state = {
  pageRecord: { name: "", value: "" }, //页面记录,如果需要纪录多个值，可以用数组，这里只记录一个值，name 用来比对，现有业务不需要
};

const mutations = {
  addPageRecord(state, record) {
    state.pageRecord = record;
  },
};

const actions = {
  addPageRecord({ commit }, record) {
    commit("addPageRecord", record);
  },
  clearPageRecord({ commit }) {
    commit("addPageRecord", { name: "", value: "" });
  },
  getRecoderByName({ state }, name) {
    return state.pageRecord.name === name ? state.pageRecord.value : null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
