import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import app from "./modules/app";
import permission from "./modules/permission";
import settings from "./modules/settings";
import user from "./modules/user";
import coupon from "./modules/coupon";
import qualification from "./modules/qualification"; //资质
import publicGoods from "./modules/publicGoods";
import comment from "./modules/comment";
import shop from "./modules/shop";
import tabStatus from "./modules/tabStatus";
import dynamicTabsStore from "./modules/dynamicTabsStore";
import pageRecorder from "./modules/pageRecorder";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    permission,
    settings,
    user,
    coupon,
    qualification,
    publicGoods,
    comment,
    shop,
    tabStatus,
    dynamicTabsStore,
    pageRecorder,
  },
  getters,
});

export default store;
