var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification new card" },
    [
      _c("Tabs", {
        staticStyle: { padding: "0 24px" },
        attrs: { tabsList: _vm.tabsList, activeName: _vm.activeName },
        on: {
          "tab-click": _vm.handleClick,
          "update:activeName": function ($event) {
            _vm.activeName = $event
          },
          "update:active-name": function ($event) {
            _vm.activeName = $event
          },
        },
      }),
      _c("div", { ref: "search-tb", staticClass: "search fl_bet" }, [
        _c("div", { staticClass: "left fl_cen" }, [
          _c(
            "div",
            { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
            [
              !_vm.expanded
                ? [
                    _c(
                      "div",
                      { staticClass: "item big" },
                      [
                        _c("el-input", {
                          staticStyle: {
                            width: "300px",
                            "margin-bottom": "20px",
                          },
                          attrs: {
                            size: "small",
                            placeholder:
                              "商品编码/ERP编码/商品名称/批准文号/生产厂家/sku编码",
                          },
                          model: {
                            value: _vm.query.keyWords,
                            callback: function ($$v) {
                              _vm.$set(_vm.query, "keyWords", $$v)
                            },
                            expression: "query.keyWords",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: {
                            size: "small",
                            placeholder: "商品名称",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.queryTableData(1)
                            },
                          },
                          model: {
                            value: _vm.query.goodsName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.query,
                                "goodsName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "query.goodsName",
                          },
                        }),
                        _c("el-input", {
                          staticStyle: {
                            "margin-left": "10px",
                            "margin-bottom": "20px",
                          },
                          attrs: {
                            size: "small",
                            placeholder: "商品ERP编码",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.queryTableData(1)
                            },
                          },
                          model: {
                            value: _vm.query.medicineCode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.query,
                                "medicineCode",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "query.medicineCode",
                          },
                        }),
                        _c("el-input", {
                          staticStyle: {
                            "margin-left": "10px",
                            "margin-bottom": "20px",
                          },
                          attrs: {
                            size: "mini",
                            placeholder: "订单号",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.queryTableData(1)
                            },
                          },
                          model: {
                            value: _vm.query.code,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.query,
                                "code",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "query.code",
                          },
                        }),
                        _c("el-input", {
                          staticStyle: {
                            "margin-left": "10px",
                            "margin-bottom": "20px",
                          },
                          attrs: {
                            size: "mini",
                            placeholder: "手机号码",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.queryTableData(1)
                            },
                          },
                          model: {
                            value: _vm.query.phone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.query,
                                "phone",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "query.phone",
                          },
                        }),
                        _c("el-input", {
                          staticStyle: {
                            "margin-left": "10px",
                            "margin-bottom": "20px",
                          },
                          attrs: {
                            size: "mini",
                            placeholder: "客户ERP编码",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.queryTableData(1)
                            },
                          },
                          model: {
                            value: _vm.query.erpShopCode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.query,
                                "erpShopCode",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "query.erpShopCode",
                          },
                        }),
                        _c("el-input", {
                          staticStyle: {
                            "margin-left": "10px",
                            "margin-bottom": "20px",
                          },
                          attrs: {
                            size: "mini",
                            placeholder: "订单ERP编码",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.queryTableData(1)
                            },
                          },
                          model: {
                            value: _vm.query.erpOrderCode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.query,
                                "erpOrderCode",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "query.erpOrderCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              "margin-left": "0px",
                              "margin-bottom": "20px",
                            },
                            attrs: { size: "small", placeholder: "开户状态" },
                            on: {
                              change: function ($event) {
                                return _vm.queryTableData(1)
                              },
                            },
                            model: {
                              value: _vm.query.accountStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.query, "accountStatus", $$v)
                              },
                              expression: "query.accountStatus",
                            },
                          },
                          [
                            _c("el-option", {
                              key: "1",
                              attrs: { label: "未开户", value: "1" },
                            }),
                            _c("el-option", {
                              key: "2",
                              attrs: { label: "已开户", value: "2" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              "margin-bottom": "20px",
                            },
                            attrs: { size: "small", placeholder: "省" },
                            on: { change: _vm.changeProvince },
                            model: {
                              value: _vm.query.province,
                              callback: function ($$v) {
                                _vm.$set(_vm.query, "province", $$v)
                              },
                              expression: "query.province",
                            },
                          },
                          _vm._l(_vm.provinceData, function (e) {
                            return _c("el-option", {
                              key: e.id,
                              attrs: { label: e.rname, value: e },
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              "margin-bottom": "20px",
                            },
                            attrs: { size: "small", placeholder: "市" },
                            on: { change: _vm.changeCity },
                            model: {
                              value: _vm.query.city,
                              callback: function ($$v) {
                                _vm.$set(_vm.query, "city", $$v)
                              },
                              expression: "query.city",
                            },
                          },
                          _vm._l(_vm.cityData, function (e) {
                            return _c("el-option", {
                              key: e.id,
                              attrs: { label: e.rname, value: e },
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              "margin-bottom": "20px",
                            },
                            attrs: { size: "small", placeholder: "市" },
                            on: { change: _vm.changeArea },
                            model: {
                              value: _vm.query.district,
                              callback: function ($$v) {
                                _vm.$set(_vm.query, "district", $$v)
                              },
                              expression: "query.district",
                            },
                          },
                          _vm._l(_vm.areaData, function (e) {
                            return _c("el-option", {
                              key: e.id,
                              attrs: { label: e.rname, value: e },
                            })
                          }),
                          1
                        ),
                        _c("el-date-picker", {
                          staticClass: "picker",
                          staticStyle: {
                            "margin-left": "10px",
                            "margin-bottom": "20px",
                          },
                          attrs: {
                            size: "small",
                            type: "daterange",
                            align: "left",
                            "unlink-panels": "",
                            "value-format": "yyyy-MM-dd",
                            "start-placeholder": "开始日期",
                            "range-separator": "-",
                            "end-placeholder": "结束日期",
                            "picker-options": _vm.pickerOptions,
                            clearable: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.queryTableData(1)
                            },
                          },
                          model: {
                            value: _vm.time,
                            callback: function ($$v) {
                              _vm.time = $$v
                            },
                            expression: "time",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
              _c(
                "div",
                { staticStyle: { width: "400px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.queryTableData(1)
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.exportTableData()
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleExpand()
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.expanded ? "收起" : "展开")),
                      _c("i", {
                        class: {
                          "el-icon-arrow-up": _vm.expanded,
                          "el-icon-arrow-down": !_vm.expanded,
                          "el-icon--right": true,
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "tables collapse_list",
          style: _vm.collapseStyle ? _vm.collapseStyle : "",
        },
        [
          _vm.tableData.length > 0
            ? _c("Order", {
                attrs: {
                  "router-type": _vm.routerType,
                  "table-data": _vm.tableData,
                },
                on: {
                  "erp-click": _vm.handleClickErp,
                  goDetail: _vm.goDetail,
                  watchBtn: _vm.watchBtn,
                  goWuliuDetail: _vm.goWuliuDetail,
                  checkCertificate: _vm.checkCertificate,
                  showQualificationReportDialog:
                    _vm.showQualificationReportDialog,
                  showQualificationInvoiceDialog:
                    _vm.showQualificationInvoiceDialog,
                  queryTableData: _vm.queryTableData,
                  recorder: _vm.recordInput,
                },
              })
            : _c("el-empty", {
                staticStyle: { height: "100%" },
                attrs: { description: "暂无数据" },
              }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.query.pages,
              page: _vm.query.current,
              limit: _vm.query.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.query, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.query, "size", $event)
              },
              pagination: _vm.changeSize,
            },
          }),
        ],
        1
      ),
      _c("saleRecordCert", {
        ref: "record-cert-dialog",
        on: { queryTableData: _vm.queryTableData },
      }),
      _c("QulificationReportDialog", { ref: "qulificationReportDialogRef" }),
      _c("QualificationInvoiceDialog", {
        ref: "qualificationInvoiceDialogRef",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }