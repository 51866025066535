<template>
  <div class="classification new card">
    <Tabs
      @tab-click="handleClick"
      style="padding: 0 24px"
      :tabsList="tabsList"
      :activeName.sync="activeName"
    ></Tabs>
    <div class="search fl_bet" ref="search-tb">
      <div class="left fl_cen">
        <div style="display: flex; flex-wrap: wrap">
          <!-- 未展开 -->
          <template v-if="!expanded">
            <div class="item big">
              <el-input
                v-model="query.keyWords"
                style="width: 300px; margin-bottom: 20px"
                size="small"
                placeholder="商品编码/ERP编码/商品名称/批准文号/生产厂家/sku编码"
              />
            </div>
          </template>
          <!-- 展开 -->
          <template v-else>
            <div style="display: flex; justify-content: space-between">
              <el-input
                v-model.trim="query.goodsName"
                size="small"
                placeholder="商品名称"
                style="margin-bottom: 20px"
                clearable
                @keyup.enter.native="queryTableData(1)"
              />
              <el-input
                v-model.trim="query.medicineCode"
                size="small"
                placeholder="商品ERP编码"
                style="margin-left: 10px; margin-bottom: 20px"
                clearable
                @keyup.enter.native="queryTableData(1)"
              />
              <el-input
                v-model.trim="query.code"
                size="mini"
                placeholder="订单号"
                style="margin-left: 10px; margin-bottom: 20px"
                clearable
                @keyup.enter.native="queryTableData(1)"
              />
              <el-input
                v-model.trim="query.phone"
                size="mini"
                placeholder="手机号码"
                style="margin-left: 10px; margin-bottom: 20px"
                clearable
                @keyup.enter.native="queryTableData(1)"
              />
              <el-input
                v-model.trim="query.erpShopCode"
                size="mini"
                placeholder="客户ERP编码"
                style="margin-left: 10px; margin-bottom: 20px"
                clearable
                @keyup.enter.native="queryTableData(1)"
              />
              <el-input
                v-model.trim="query.erpOrderCode"
                size="mini"
                placeholder="订单ERP编码"
                style="margin-left: 10px; margin-bottom: 20px"
                clearable
                @keyup.enter.native="queryTableData(1)"
              />
            </div>
            <div style="display: flex; justify-content: space-between">
              <el-select
                v-model="query.accountStatus"
                size="small"
                placeholder="开户状态"
                style="margin-left: 0px; margin-bottom: 20px"
                @change="queryTableData(1)"
              >
                <el-option key="1" label="未开户" value="1" />
                <el-option key="2" label="已开户" value="2" />
              </el-select>

              <el-select
                v-model="query.province"
                size="small"
                placeholder="省"
                style="margin-left: 10px; margin-bottom: 20px"
                @change="changeProvince"
              >
                <el-option
                  v-for="e in provinceData"
                  :label="e.rname"
                  :value="e"
                  :key="e.id"
                ></el-option>
              </el-select>

              <el-select
                v-model="query.city"
                size="small"
                placeholder="市"
                style="margin-left: 10px; margin-bottom: 20px"
                @change="changeCity"
              >
                <el-option
                  v-for="e in cityData"
                  :label="e.rname"
                  :value="e"
                  :key="e.id"
                ></el-option>
              </el-select>

              <el-select
                v-model="query.district"
                size="small"
                placeholder="市"
                style="margin-left: 10px; margin-bottom: 20px"
                @change="changeArea"
              >
                <el-option
                  v-for="e in areaData"
                  :label="e.rname"
                  :value="e"
                  :key="e.id"
                ></el-option>
              </el-select>

              <el-date-picker
                v-model="time"
                size="small"
                class="picker"
                style="margin-left: 10px; margin-bottom: 20px"
                type="daterange"
                align="left"
                unlink-panels
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                range-separator="-"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                clearable
                @change="queryTableData(1)"
              />
            </div>
          </template>
          <div style="width: 400px">
            <el-button
              size="small"
              type="primary"
              style="margin-left: 10px"
              @click="queryTableData(1)"
              >查询</el-button
            >
            <el-button size="small" @click="reset()">重置</el-button>
            <el-button
              size="small"
              type="primary"
              style="margin-left: 10px"
              @click="exportTableData()"
              >导出</el-button
            >
            <el-button type="text" size="small" @click="handleExpand()">
              {{ expanded ? "收起" : "展开"
              }}<i
                :class="{
                  'el-icon-arrow-up': expanded,
                  'el-icon-arrow-down': !expanded,
                  'el-icon--right': true,
                }"
              ></i>
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tables collapse_list"
      :style="collapseStyle ? collapseStyle : ''"
    >
      <Order
        v-if="tableData.length > 0"
        :router-type="routerType"
        :table-data="tableData"
        @erp-click="handleClickErp"
        @goDetail="goDetail"
        @watchBtn="watchBtn"
        @goWuliuDetail="goWuliuDetail"
        @checkCertificate="checkCertificate"
        @showQualificationReportDialog="showQualificationReportDialog"
        @showQualificationInvoiceDialog="showQualificationInvoiceDialog"
        @queryTableData="queryTableData"
        @recorder="recordInput"
      />
      <el-empty v-else description="暂无数据" style="height: 100%" />
    </div>
    <!-- 分页 -->
    <div class="bottom">
      <Pagination
        :total="query.pages"
        :page.sync="query.current"
        :limit.sync="query.size"
        @pagination="changeSize"
      />
    </div>
    <!-- 线下打款凭证 -->
    <saleRecordCert ref="record-cert-dialog" @queryTableData="queryTableData" />
    <!-- 资质报告需求弹窗 -->
    <QulificationReportDialog ref="qulificationReportDialogRef" />
    <!-- 资质报告发票弹窗 -->
    <QualificationInvoiceDialog ref="qualificationInvoiceDialogRef" />
  </div>
</template>
<script>
// import { correspondingVal } from '@/util/validate'
import { mapState, mapGetters } from "vuex";
import {
  goodorderDetail,
  buyPageList,
  surePrint,
  exportTableData,
} from "@/api/servicecenter/pendingPayment.js";
import { checkStatus } from "@/utils/status";
import Order from "@/components/order";
import Pagination from "@/components/Pagination";
import Tabs from "@/components/Tabs";
import QulificationReportDialog from "./dialog/qulificationReportDialog.vue";
import QualificationInvoiceDialog from "./dialog/qualificationInvoiceDialog.vue";
import { regionAreaTree } from "@/api/saleRule/saleF";
import saleRecordCert from "./components/saleRecordCert.vue";

export default {
  name: "SaleList",
  components: {
    Order,
    Pagination,
    Tabs,
    saleRecordCert,
    QulificationReportDialog,
    QualificationInvoiceDialog,
  },
  data() {
    return {
      ageingTag: "",
      expanded: false, //搜索条件是否展开
      collapseStyle: "",
      activeName: "first", // tabs 传的值
      tabsList: [
        // tabs 传的值
        {
          name: "first",
          label: "所有订单",
          value: 0,
        },
        {
          name: "1",
          label: "待付款",
          value: 0,
        },
        {
          name: "11",
          label: "待开户",
          value: 0,
        },
        {
          name: "2",
          label: "待发货",
          value: 0,
        },
        {
          name: "3",
          label: "配送中",
          value: 0,
        },
        {
          name: "8",
          label: "配送完成",
          value: 0,
        },
        {
          name: "9",
          label: "已完成",
          value: 0,
        },
        {
          name: "10",
          label: "售后",
          value: 0,
        },
        {
          name: "12",
          label: "待确款",
          value: 0,
        },
        {
          name: "5",
          label: "已取消",
          value: 0,
        },
      ],
      routerType: "sale", // 区分是哪个页面进入的
      awaitPayQty: 0,
      waitEnterQty: 0,
      awaitShipmentsQty: 0,
      alwaySendQty: 0,
      awaitReceivingQty: 0,
      // activeName: 'first',
      isLoading: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近7天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近15天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近30天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        keyWords: "", // 未展开的搜索条件
        current: 1,
        size: 10,
        pages: 0,
        code: "",
        goodsName: "",
        phone: "",
        startOrderTime: "",
        endOrderTime: "",
        type: "SALE",
        status: "",
        province: "",
        city: "",
        district: "",
      },
      activeNames: "", // 展开list
      tableData: [],
      tableList: [],
      time: [],
      payid: "",
      provinceData: [],
      cityData: [],
      areaData: [],
      isRecord: false, //离开页面是否记录当前输入框的值
      recoderKey: "ordercenter-sales",
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState("App", {
      selectList: "selectList",
    }),
    getStatus() {
      return (type, status) => checkStatus(type, status);
    },
  },
  beforeDestroy() {
    if (!this.isRecord) {
      this.$store.dispatch("pageRecorder/clearPageRecord");
    }
  },
  methods: {
    // 资质发票弹窗
    showQualificationInvoiceDialog(e) {
      this.$refs.qualificationInvoiceDialogRef.openDialog(e);
    },
    // 资质报告弹窗
    showQualificationReportDialog(e) {
      this.$refs.qulificationReportDialogRef.openDialog(e);
    },
    checkCertificate(row) {
      this.$refs["record-cert-dialog"].init(row, "check", "sale");
    },
    handleExpand() {
      this.expanded = !this.expanded;
      this.$nextTick(() => {
        this.collapseStyleFn();
      });
    },
    collapseStyleFn() {
      console.log(this.$refs["search-tb"].offsetHeight);
      let clientHeight = this.$refs["search-tb"].offsetHeight + 110;
      console.log(clientHeight);
      this.collapseStyle = `height: calc(100% - ${clientHeight}px);`;
    },
    // 获取省市区列表
    getRegionAreaTree(leve) {
      let data = {
        level: 3,
      };
      regionAreaTree(data).then((res) => {
        if (res.code === 0) {
          this.provinceData = res.data;
          console.log(res.data);
        }
      });
    },

    changeProvince(e) {
      console.log(e);
      this.query.province = e.rname;
      this.query.city = "";
      this.cityData = e.children;
    },

    changeCity(e) {
      this.query.district = "";
      this.query.city = e.rname;
      this.areaData = e.children;
      this.$forceUpdate();
    },

    changeArea(e) {
      this.query.district = e.rname;
      this.$forceUpdate();
    },
    // 到处订单数据
    exportTableData() {
      if (this.time != null && this.time.length > 0) {
        this.query.startOrderTime = this.time[0];
        this.query.endOrderTime = this.time[1];
      } else {
        this.query.startOrderTime = null;
        this.query.endOrderTime = null;
      }
      // console.log(this.query)
      let params = {
        type: this.query.type,
        code: this.query.code,
        status: this.query.status,
        startOrderTime: this.query.startOrderTime,
        endOrderTime: this.query.endOrderTime,
        phone: this.query.phone,
        goodsName: this.query.goodsName,
      };
      if (this.expanded) {
        params.keyWords = "";
      } else {
        params.keyWords = this.query.keyWords;
      }
      exportTableData(params).then((res) => {
        console.log(res);
      });
    },
    // 重置
    reset() {
      this.time = [];
      Object.assign(this.query, {
        keyWords: "",
        code: "",
        goodsName: "",
        phone: "",
        accountStatus: "",
        province: "",
        city: "",
        district: "",
        erpOrderCode: "",
        erpShopCode: "",
        status: "",
      });
      this.queryTableData(1);
    },

    handleClickErp(tab, event) {
      this.activeName = "first";
      this.query.status = "";
      this.queryTableData(1);
    },
    handleClick(tab, event) {
      if (this.activeName == "first") {
        this.query.status = "";
      } else {
        this.query.status = this.activeName;
      }
      this.$store.dispatch("tabStatus/setSalesTab", this.activeName); //缓存tab状态

      this.queryTableData(1);
    },
    watchBtn(item) {
      surePrint({ id: item }).then((res) => {
        if (res.data.code == 0) {
          this.queryTableData(1);
        }
      });
    },
    validateBtn(val, row, name) {
      return correspondingVal(name, row, val, this.selectList);
    },
    queryTableData(current) {
      if (current) {
        this.query.current = current;
      }
      if (this.time != null && this.time.length > 0) {
        this.query.startOrderTime = this.time[0];
        this.query.endOrderTime = this.time[1];
      } else {
        this.query.startOrderTime = null;
        this.query.endOrderTime = null;
      }
      if (this.expanded) {
        this.query.keyWords = "";
      } else {
        // params.keyWords = this.query.keyWords;
      }
      this.query.ageingTag = this.ageingTag ? this.ageingTag : "";
      buyPageList(this.query)
        .then((res) => {
          this.tabsList[1].value = res.data.awaitPayQty; //待付款
          this.tabsList[2].value = res.data.awaitExamine; //待审核
          this.tabsList[3].value = res.data.awaitShipmentsQty; //待发货
          this.tabsList[4].value = res.data.awaitReceivingQty; //配送中
          this.tabsList[5].value = res.data.signQty; //配送完成
          this.tabsList[7].value = res.data.afterSaleQty; //售后
          this.tabsList[8].value = res.data.waitEnterQty; //待确款 数量

          this.isLoading = false;
          if (res.data && res.code != 1) {
            this.tableData = res.data.page.records || [];
            this.query.pages = res.data.page.total;
          } else {
            this.tableData = [];
            this.query.pages = 0;
          }
          this.ageingTag = "";
          console.log(this.ageingTag, "ageingTag");
        })
        .catch(() => {
          this.tableData = [];
          this.isLoading = false;
        });
    },
    handleChange(val) {
      console.log(val);
      this.isLoading = true;
      this.tableList = [];
      this.getdemoList();
    },
    async getdemoList() {
      const activeId = this.activeNames;
      this.activeNames = "";
      if (activeId) {
        await goodorderDetail(activeId)
          .then((res) => {
            this.tableList = res.data.detailList;
            this.isLoading = false;
            this.$nextTick(() => {
              this.activeNames = activeId;
            });
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    changePage(val) {
      this.isLoading = true;
      this.query.current = val.page;
      this.queryTableData();
    },
    changeSize(val) {
      this.isLoading = true;
      this.query.size = val.limit;
      this.queryTableData();
    },
    typeSel(val) {
      this.isLoading = true;
      if (val) {
        this.query.type = val;
      } else {
        this.query.type = "";
      }
      this.query.current = 1;
      this.queryTableData();
    },
    stopClick(e) {
      e.stopPropagation();
    },
    // 物流详情
    goWuliuDetail(id, orderId) {
      this.recordInput();
      this.$router.push({
        path: "/ordercenter/ordercenter-detail",
        query: { id: id, orderId: orderId },
      });
    },
    goDetail(id, orderId, selfCheckStatus) {
      if (selfCheckStatus) {
        this.$alert(
          "自营商品的订单需要地区公司、省区公司审核完成，医药公司再做发货",
          "订单审核中",
          {
            confirmButtonText: "确定",
            callback: () => {},
          }
        );
        return;
      }
      this.recordInput();
      this.$router.push({
        path: "/ordercenter/ordercenter-detail",
        query: { id: id, orderId: orderId },
      });
    },
    // 导出
    async exportFile() {
      await exportPage(this.query).then((res) => {
        downloadFile(res.data, "销售订单");
      });
    },
    // 记录输入框的值
    recordInput() {
      this.isRecord = true;
      this.$store.dispatch("pageRecorder/addPageRecord", {
        name: this.recoderKey,
        value: { ...this.query, expanded: this.expanded, time: this.time },
      });
    },
    // 获取页面记录
    getInputRecoderValue() {
      return new Promise((resolve) => {
        this.$store
          .dispatch("pageRecorder/getRecoderByName", this.recoderKey)
          .then((res) => {
            if (res) {
              this.query = res;
              this.expanded = res.expanded || false;
              this.time = res.time || [];
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },
  },
  created() {},
  async mounted() {
    // 获取输入框的纪录值
    await this.getInputRecoderValue();
    if (this.$store.state.tabStatus.salesTab) {
      this.activeName = this.$store.state.tabStatus.salesTab;
      this.handleClick();
    } else {
      this.activeName = this.$route.query.tab ? this.$route.query.tab : "first";
      this.ageingTag = this.$route.query.ageingTag
        ? this.$route.query.ageingTag
        : "";
      if (this.activeName == "first") {
        this.query.status = "";
      } else {
        this.query.status = this.activeName;
      }
      this.queryTableData();
    }
    this.getRegionAreaTree();
    this.collapseStyleFn(); //table 动态样式
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/index.scss";
@import "../../styles/main";

.classification {
  padding: 0px;

  .headerTabs {
    padding: 0px 24px;
    border-bottom: 1px solid #f5f5f5;
  }

  .search,
  .collapse_list {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .collapse_list {
    height: calc(100% - 360px);
  }

  .bottom {
    margin: 0 24px;
  }
}

.card {
  overflow: hidden;
}

.collapse_list {
  overflow: auto;
}
</style>
