var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent" },
    [
      _c("Tabs", {
        staticStyle: { padding: "0 24px" },
        attrs: { tabsList: _vm.tabsList, activeName: _vm.activeName },
        on: {
          "tab-click": _vm.handleClick,
          "update:activeName": function ($event) {
            _vm.activeName = $event
          },
          "update:active-name": function ($event) {
            _vm.activeName = $event
          },
        },
      }),
      _c("div", { staticClass: "topHeader" }, [
        _c(
          "div",
          { staticStyle: { width: "calc(100% - 230px)" } },
          [
            _c(
              "el-form",
              {
                ref: "formTool",
                staticStyle: { display: "flex" },
                attrs: { model: _vm.formTool, inline: true },
              },
              [
                !_vm.expanded
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "item big",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "300px",
                              "margin-right": "16px",
                            },
                            attrs: {
                              size: "small",
                              placeholder:
                                "服务单号/订单编号/商品名称/客户名称",
                            },
                            model: {
                              value: _vm.formTool.keyWords,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formTool,
                                  "keyWords",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formTool.keyWords",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c(
                        "div",
                        {
                          staticClass: "item big",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "code", size: "small" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: "服务单号",
                                },
                                model: {
                                  value: _vm.formTool.code,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formTool,
                                      "code",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formTool.code",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "orderCode", size: "small" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: "订单编号",
                                },
                                model: {
                                  value: _vm.formTool.orderCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formTool,
                                      "orderCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formTool.orderCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "goodsName", size: "small" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: "商品名称",
                                },
                                model: {
                                  value: _vm.formTool.goodsName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formTool,
                                      "goodsName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formTool.goodsName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "shopName", size: "small" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: "客户名称",
                                },
                                model: {
                                  value: _vm.formTool.shopName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formTool,
                                      "shopName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formTool.shopName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "date", size: "small" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "daterange",
                                  "value-format": "yyyy-MM-dd",
                                  "range-separator": "-",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                },
                                model: {
                                  value: _vm.formTool.date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formTool, "date", $$v)
                                  },
                                  expression: "formTool.date",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "content", size: "small" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "申请类型",
                                  },
                                  model: {
                                    value: _vm.formTool.applyForType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formTool,
                                        "applyForType",
                                        $$v
                                      )
                                    },
                                    expression: "formTool.applyForType",
                                  },
                                },
                                _vm._l(_vm.options, function (li) {
                                  return _c("el-option", {
                                    key: li.value,
                                    attrs: { label: li.label, value: li.value },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { width: "220px" } },
          [
            _c("MyButton", {
              attrs: { type: "primary", text: "查询" },
              on: {
                click: function ($event) {
                  return _vm.getList(1)
                },
              },
            }),
            _c("MyButton", {
              attrs: { type: "", text: "重置" },
              on: {
                click: function ($event) {
                  return _vm.reset()
                },
              },
            }),
            _c(
              "el-button",
              {
                attrs: { type: "text", size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.handleExpand()
                  },
                },
              },
              [
                _vm._v(" " + _vm._s(_vm.expanded ? "收起" : "展开") + " "),
                _c("i", {
                  class: {
                    "el-icon-arrow-up": _vm.expanded,
                    "el-icon-arrow-down": !_vm.expanded,
                    "el-icon--right": true,
                  },
                }),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "center" },
        [
          _vm.tableData.length > 0
            ? _c("MyTable", {
                attrs: {
                  "table-data": _vm.tableData,
                  index: _vm.index,
                  selection: _vm.selection,
                  page: _vm.page,
                  operation: _vm.operation,
                  "table-option": _vm.tableOption,
                },
                on: {
                  "update:tableOption": function ($event) {
                    _vm.tableOption = $event
                  },
                  "update:table-option": function ($event) {
                    _vm.tableOption = $event
                  },
                  "page-change": _vm.getList,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "amount",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(" ￥" + _vm._s(scope.row.amount) + " "),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "countDownTime",
                      fn: function (scope) {
                        return _vm.activeName == "WAIT_EVIDENCE" ||
                          _vm.activeName == "REJECT"
                          ? [
                              scope.row.evidenceRemainTimestamp > 0
                                ? _c("el-statistic", {
                                    ref: "statistic",
                                    attrs: {
                                      format: "HH:mm:ss",
                                      value:
                                        Date.now() +
                                        parseInt(
                                          scope.row.evidenceRemainTimestamp
                                        ),
                                      title: "",
                                      "time-indices": "",
                                      "value-style": {
                                        color: "#f5222d",
                                        fontSize: "14px",
                                      },
                                    },
                                    on: {
                                      finish: function ($event) {
                                        return _vm.hilarity(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          : undefined
                      },
                    },
                    {
                      key: "countDownTimeAfter",
                      fn: function (scope) {
                        return _vm.activeName == "CHECKING" ||
                          _vm.activeName == "SALEING" ||
                          _vm.activeName == "WAIT_SALE" ||
                          _vm.activeName == "REFUNDING"
                          ? [
                              _vm.activeName == "CHECKING" &&
                              scope.row.auditRemainTimestamp > 0
                                ? _c("el-statistic", {
                                    ref: "statistic",
                                    attrs: {
                                      format: "DD天HH:mm:ss",
                                      value:
                                        Date.now() +
                                        parseInt(
                                          scope.row.auditRemainTimestamp
                                        ),
                                      title: "",
                                      "time-indices": "",
                                      "value-style": {
                                        color: "#f5222d",
                                        fontSize: "14px",
                                      },
                                    },
                                    on: {
                                      finish: function ($event) {
                                        return _vm.hilarity(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.activeName == "REFUNDING" &&
                              scope.row.offlinePaymentRefundsTimestamp > 0
                                ? _c("el-statistic", {
                                    ref: "statistic",
                                    attrs: {
                                      format: "DD天HH:mm:ss",
                                      value:
                                        Date.now() +
                                        parseInt(
                                          scope.row
                                            .offlinePaymentRefundsTimestamp
                                        ),
                                      title: "",
                                      "time-indices": "",
                                      "value-style": {
                                        color: "#f5222d",
                                        fontSize: "14px",
                                      },
                                    },
                                    on: {
                                      finish: function ($event) {
                                        return _vm.hilarity(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.activeName == "WAIT_SALE" &&
                              scope.row.deliveryRemainTimestamp > 0
                                ? _c("el-statistic", {
                                    ref: "statistic",
                                    attrs: {
                                      format: "DD天HH:mm:ss",
                                      value:
                                        Date.now() +
                                        parseInt(
                                          scope.row.deliveryRemainTimestamp
                                        ),
                                      title: "",
                                      "time-indices": "",
                                      "value-style": {
                                        color: "#f5222d",
                                        fontSize: "14px",
                                      },
                                    },
                                    on: {
                                      finish: function ($event) {
                                        return _vm.hilarity(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.activeName == "SALEING" &&
                              scope.row.receiveRemainTimestamp > 0
                                ? _c("el-statistic", {
                                    ref: "statistic",
                                    attrs: {
                                      format: "DD天HH:mm:ss",
                                      value:
                                        Date.now() +
                                        parseInt(
                                          scope.row.receiveRemainTimestamp
                                        ),
                                      title: "",
                                      "time-indices": "",
                                      "value-style": {
                                        color: "#f5222d",
                                        fontSize: "14px",
                                      },
                                    },
                                    on: {
                                      finish: function ($event) {
                                        return _vm.hilarity(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          : undefined
                      },
                    },
                    {
                      key: "caozuo",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.detail(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 详情 ")]
                          ),
                          (scope.row.status == "审核中" ||
                            scope.row.status == "待确款") &&
                          (scope.row.type == "退款" ||
                            scope.row.type == "退货退款") &&
                          !scope.row.beExistsVoucher &&
                          scope.row.payType == 3
                            ? _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.afterSalesControl == "1",
                                    underline: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitAgreeBefore(
                                        scope.row,
                                        scope.row.type
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 同意 ")]
                              )
                            : _vm._e(),
                          (scope.row.status == "审核中" ||
                            scope.row.status == "待确款") &&
                          (scope.row.type == "退款" ||
                            scope.row.type == "退货退款") &&
                          scope.row.payType != 3
                            ? _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.afterSalesControl == "1",
                                    underline: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitAgreeBefore(
                                        scope.row,
                                        scope.row.type
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 同意 ")]
                              )
                            : _vm._e(),
                          scope.row.platformStatus == "WAIT_EVIDENCE"
                            ? _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.afterSalesControl == "1",
                                    underline: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitProof(
                                        scope.row,
                                        scope.row.type
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 举证 ")]
                              )
                            : _vm._e(),
                          (scope.row.status == "审核中" ||
                            scope.row.status == "待确款") &&
                          scope.row.type != "商家退款" &&
                          !scope.row.beExistsVoucher
                            ? _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.afterSalesControl == "1",
                                    underline: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.aftersaleReject(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" 驳回 ")]
                              )
                            : _vm._e(),
                          scope.row.status == "退货中"
                            ? _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.afterSalesControl == "1",
                                    underline: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickAftersaleDelivery(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 确认收货 ")]
                              )
                            : _vm._e(),
                          scope.row.status == "退款中" &&
                          scope.row.payType == 3 &&
                          !scope.row.beExistsVoucher
                            ? _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.afterSalesControl == "1",
                                    underline: false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.uploadCredentials(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" 上传凭证 ")]
                              )
                            : _vm._e(),
                          scope.row.beExistsVoucher
                            ? _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkCertificate(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" 查看凭证 ")]
                              )
                            : _vm._e(),
                          scope.row.status == "待确款" &&
                          scope.row.beExistsVoucher
                            ? _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modifyCredentials(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" 修改凭证 ")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : _c("el-empty", {
                staticStyle: { height: "100%" },
                attrs: { description: "暂无数据" },
              }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "拒绝说明",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "请输入内容",
              maxlength: "200",
              "show-word-limit": "",
            },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
          _c(
            "ImgUpload",
            {
              staticStyle: { position: "relative", top: "0px" },
              attrs: { limit: 3 },
              model: {
                value: _vm.imageList,
                callback: function ($$v) {
                  _vm.imageList = $$v
                },
                expression: "imageList",
              },
            },
            [
              _c("template", { slot: "tip" }, [
                _c(
                  "p",
                  {
                    staticClass: "el-upload__tip",
                    staticStyle: { margin: "0px" },
                  },
                  [
                    _vm._v(
                      " 备注:图片格式为jpg或png，建议尺寸100*100，图片大小不得超过5M;最多上传三张图片 "
                    ),
                  ]
                ),
              ]),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("resetDialog", { ref: "rest-dialog", on: { getList: _vm.getList } }),
      _c("checkDialog", {
        ref: "check-dialog",
        on: { resetSubmit: _vm.resetSubmit },
      }),
      _c("proofDialog", { ref: "proof-dialog", on: { getList: _vm.getList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }