var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "staus_bar fl_bet border" }, [
          _c("div", { staticClass: "detail_left" }, [
            _c("div", { staticClass: "fl_cen" }, [
              _vm.orderList &&
              _vm.orderList.status != undefined &&
              _vm.orderList.status != null
                ? _c(
                    "div",
                    { staticClass: "status" },
                    [
                      _c("el-image", {
                        staticClass: "icon_status",
                        attrs: { src: _vm.getStatus(1, _vm.orderList.status) },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "name",
                          class: _vm.getStatus(3, _vm.orderList.status),
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getStatus(2, _vm.orderList.status)) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单编号")]),
                _c("div", { staticStyle: { height: "22px" } }, [
                  _vm._v(_vm._s(_vm.orderList.orderCode || "")),
                ]),
              ]),
              _c("div", { staticClass: "items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("下单时间")]),
                _c("div", { staticStyle: { height: "22px" } }, [
                  _vm._v(_vm._s(_vm.orderList.orderTime || "")),
                ]),
              ]),
              _vm.orderList.status == 1 && _vm.orderList.payTime
                ? _c("div", { staticClass: "items" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("付款时间")]),
                    _c(
                      "div",
                      { staticStyle: { height: "22px", color: "#e4393c" } },
                      [_vm._v(" " + _vm._s(_vm.orderList.payTime || " ") + " ")]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "detail_right" }),
        ]),
        _c("div", { staticClass: "staus_plan fl_left" }, [
          _c(
            "div",
            { staticClass: "step", class: _vm.indexStep >= 1 ? "active" : "" },
            [
              _c("div", { staticClass: "fl_center" }, [
                _c(
                  "div",
                  { staticClass: "status" },
                  [
                    _vm.indexStep > 1
                      ? _c("el-image", {
                          staticClass: "icon_svg",
                          attrs: { src: _vm.icon },
                        })
                      : _c("div", { staticClass: "idx" }, [_vm._v("1")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "name",
                    class: _vm.indexStep > 1 ? "sueccss" : "",
                  },
                  [_vm._v(" 提交订单 ")]
                ),
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.orderList.orderTime || "")),
              ]),
            ]
          ),
          _c("div", {
            staticClass: "line",
            class: _vm.indexStep >= 1 ? "active" : "",
          }),
          _c(
            "div",
            { staticClass: "step", class: _vm.indexStep >= 2 ? "active" : "" },
            [
              _c("div", { staticClass: "fl_center" }, [
                _c(
                  "div",
                  { staticClass: "status fl_center" },
                  [
                    _vm.indexStep > 2
                      ? _c("el-image", {
                          staticClass: "icon_svg",
                          attrs: { src: _vm.icon },
                        })
                      : _c("div", { staticClass: "idx" }, [_vm._v("2")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "name",
                    class: _vm.indexStep > 2 ? "sueccss" : "",
                  },
                  [_vm._v(" 付款成功 ")]
                ),
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.orderList.payTime || "")),
              ]),
            ]
          ),
          _c("div", {
            staticClass: "line",
            class: _vm.indexStep >= 2 ? "active" : "",
          }),
          _c(
            "div",
            { staticClass: "step", class: _vm.indexStep >= 3 ? "active" : "" },
            [
              _c("div", { staticClass: "fl_center" }, [
                _c(
                  "div",
                  { staticClass: "status" },
                  [
                    _vm.indexStep > 3
                      ? _c("el-image", {
                          staticClass: "icon_svg",
                          attrs: { src: _vm.icon },
                        })
                      : _c("div", { staticClass: "idx" }, [_vm._v("3")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "name",
                    class: _vm.indexStep > 3 ? "sueccss" : "",
                  },
                  [_vm._v(" 商品出库 ")]
                ),
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.orderList.deliveryTime || "")),
              ]),
            ]
          ),
          _c("div", {
            staticClass: "line",
            class: _vm.indexStep >= 3 ? "active" : "",
          }),
          _c(
            "div",
            { staticClass: "step", class: _vm.indexStep >= 4 ? "active" : "" },
            [
              _c("div", { staticClass: "fl_center" }, [
                _c(
                  "div",
                  { staticClass: "status" },
                  [
                    _vm.indexStep > 4
                      ? _c("el-image", {
                          staticClass: "icon_svg",
                          attrs: { src: _vm.icon },
                        })
                      : _c("div", { staticClass: "idx" }, [_vm._v("4")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "name",
                    class: _vm.indexStep > 4 ? "sueccss" : "",
                  },
                  [_vm._v(" 配送完成 ")]
                ),
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.orderList.signDate || "")),
              ]),
            ]
          ),
          _c("div", {
            staticClass: "line",
            class: _vm.indexStep >= 4 ? "active" : "",
          }),
          _c(
            "div",
            { staticClass: "step", class: _vm.indexStep >= 5 ? "active" : "" },
            [
              _c("div", { staticClass: "fl_center" }, [
                _c(
                  "div",
                  { staticClass: "status" },
                  [
                    _vm.indexStep == 5
                      ? _c("el-image", {
                          staticClass: "icon_svg",
                          attrs: { src: _vm.icon },
                        })
                      : _c("div", { staticClass: "idx" }, [_vm._v("5")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "name",
                    class: _vm.indexStep > 5 ? "sueccss" : "",
                  },
                  [_vm._v(" 交易完成 ")]
                ),
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.orderList.finishDate || "")),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "info card" },
        [
          _vm.orderLogisticsList
            ? _c(
                "el-tabs",
                {
                  staticClass: "headerTabs",
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.orderLogistics,
                    callback: function ($$v) {
                      _vm.orderLogistics = $$v
                    },
                    expression: "orderLogistics",
                  },
                },
                _vm._l(_vm.orderLogisticsList, function (item, index) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: index,
                      attrs: {
                        label: item.label,
                        name: item.expressDeliveryNumber,
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { "font-size": "14px" },
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          item.expressTag == 1
                            ? _c("div", [
                                _c("div", [
                                  _vm._v(
                                    "自主配送：" +
                                      _vm._s(item.deliveryDriver || "--")
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "联系电话：" + _vm._s(item.driverPhone)
                                  ),
                                ]),
                              ])
                            : _c("div", [
                                _c(
                                  "div",
                                  { staticStyle: { "line-height": "20px" } },
                                  [_vm._v(" " + _vm._s(item.kuaidiName) + " ")]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "line-height": "20px" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.expressDeliveryNumber) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                        ]
                      ),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { name: "1" } },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "div",
                      {
                        staticStyle: { "line-height": "20px", height: "80px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#f56c6c",
                              "font-weight": "600",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.expressData.step || "暂无物流数据") +
                                " "
                            ),
                          ]
                        ),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.expressData.description || "") +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { "padding-top": "10px" } },
                    [
                      _vm.expressData.child && _vm.expressData.child.length > 0
                        ? _c(
                            "el-timeline",
                            _vm._l(
                              _vm.expressData.child,
                              function (activity, index) {
                                return _c(
                                  "el-timeline-item",
                                  { key: index, attrs: { color: "#F5222D" } },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { "font-weight": "600" } },
                                      [_vm._v(_vm._s(activity.step || ""))]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(activity.description || "")
                                      ),
                                    ]),
                                    activity.image
                                      ? _c("el-image", {
                                          staticStyle: {
                                            display: "block",
                                            width: "100px",
                                            height: "100px",
                                          },
                                          attrs: {
                                            src: activity.image || "",
                                            "preview-src-list": [
                                              activity.image || "",
                                            ],
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "info card fl_cen" }, [
        _c("div", { staticClass: "info_list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("收件人信息")]),
          _c("div", { staticClass: "lists" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("收货人：")]),
              _vm._v(_vm._s(_vm.orderList.contactsName || "--") + " "),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("地址：")]),
              _vm._v(
                _vm._s(_vm.orderList.province || "") +
                  _vm._s(_vm.orderList.city || "") +
                  _vm._s(_vm.orderList.district || "") +
                  _vm._s(_vm.orderList.address || "") +
                  " "
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("手机号：")]),
              _vm._v(_vm._s(_vm.orderList.contactsPhone || "--") + " "),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("配送方式：")]),
              _vm._v(
                _vm._s(_vm.getTransWayText(_vm.orderList.dispatchWay)) + " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "info_list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("客户信息")]),
          _c("div", { staticClass: "lists" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("客户名称：")]),
              _vm._v(_vm._s(_vm.orderList.payShopName || "--") + " "),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "info_list",
            staticStyle: { "padding-right": "40px" },
          },
          [
            _vm.orderList.status == 2 ||
            _vm.orderList.status == 3 ||
            _vm.orderList.status == 4 ||
            _vm.orderList.status == 8
              ? _c(
                  "div",
                  {
                    staticClass: "lists",
                    staticStyle: { "margin-top": "0px" },
                  },
                  [
                    _c("Tabs", {
                      staticClass: "title",
                      attrs: {
                        tabsList: _vm.tabsList,
                        activeName: _vm.tabsActiveName,
                      },
                      on: {
                        "update:activeName": function ($event) {
                          _vm.tabsActiveName = $event
                        },
                        "update:active-name": function ($event) {
                          _vm.tabsActiveName = $event
                        },
                        "tab-click": _vm.handleChange,
                      },
                    }),
                    _vm.tabsActiveName == "1"
                      ? _c(
                          "div",
                          { staticClass: "list_input fl_cen" },
                          [
                            _c(
                              "div",
                              [
                                _c("el-autocomplete", {
                                  staticClass: "formInput",
                                  attrs: {
                                    disabled: _vm.deliverControl == "1",
                                    placeholder: "填写快递公司",
                                    "fetch-suggestions": _vm.querySearchAsync,
                                    "value-key": "name",
                                  },
                                  on: { select: _vm.handleSelect },
                                  model: {
                                    value: _vm.form.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "companyName", $$v)
                                    },
                                    expression: "form.companyName",
                                  },
                                }),
                                _c("el-input", {
                                  staticClass: "formInput",
                                  attrs: {
                                    disabled: _vm.deliverControl == "1",
                                    onkeyup:
                                      "this.value=this.value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                                    placeholder: "填写快递单号",
                                  },
                                  model: {
                                    value: _vm.form.nums,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "nums", $$v)
                                    },
                                    expression: "form.nums",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "formbutton",
                                attrs: {
                                  disabled: _vm.deliverControl == "1",
                                  loading: _vm.isBtnLoading,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.addKuaidi.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(_vm._s("提交"))]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "formbutton",
                                attrs: {
                                  loading: _vm.isBtnLoading,
                                  disabled: _vm.disabledUpdate,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.updateLogistics.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s("更新"))]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "list_input fl_cen" },
                          [
                            _c(
                              "div",
                              [
                                _c("el-input", {
                                  staticClass: "formInput",
                                  attrs: {
                                    disabled: _vm.deliverControl == "1",
                                    placeholder: "填写司机姓名",
                                  },
                                  model: {
                                    value: _vm.form.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "companyName", $$v)
                                    },
                                    expression: "form.companyName",
                                  },
                                }),
                                _c("el-input", {
                                  staticClass: "formInput",
                                  attrs: {
                                    disabled: _vm.deliverControl == "1",
                                    placeholder: "填写司机联系方式",
                                  },
                                  model: {
                                    value: _vm.form.nums,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "nums", $$v)
                                    },
                                    expression: "form.nums",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "formbutton",
                                attrs: {
                                  disabled: _vm.deliverControl == "1",
                                  loading: _vm.isBtnLoading,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.addKuaidi.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(_vm._s("提交"))]
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "lists",
                    staticStyle: { "margin-top": "0px" },
                  },
                  [
                    this.tabsActiveName == "2"
                      ? _c("div", [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("配送信息"),
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("span", [_vm._v("司机姓名：")]),
                            _vm._v(
                              _vm._s(_vm.orderList.deliveryDriver || "--") + " "
                            ),
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("span", [_vm._v("联系方式：")]),
                            _vm._v(
                              _vm._s(_vm.orderList.driverPhone || "--") + " "
                            ),
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("span", [_vm._v("预计送达：")]),
                            _vm._v(
                              _vm._s(_vm.orderList.finishDate || "--") + " "
                            ),
                          ]),
                        ])
                      : _c("div", [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("配送信息"),
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("span", [_vm._v("快递公司：")]),
                            _vm._v(
                              _vm._s(_vm.orderList.kuaidiName || "--") + " "
                            ),
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("span", [_vm._v("快递单号：")]),
                            _vm._v(_vm._s(_vm.orderList.number || "--") + " "),
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("span", [_vm._v("预计送达：")]),
                            _vm._v(
                              _vm._s(_vm.orderList.finishDate || "--") + " "
                            ),
                          ]),
                        ]),
                  ]
                ),
          ]
        ),
        _c("div", { staticClass: "info_list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("付款信息")]),
          _c("div", { staticClass: "lists" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("实付总额：")]),
              _vm._v("￥" + _vm._s(_vm.orderList.totalPrice || "--") + " "),
            ]),
            _c("div", { staticClass: "payed-inline" }, [
              _c("span", [_vm._v("支付方式：")]),
              _vm.orderList.balanceAmount &&
              parseFloat(_vm.orderList.balanceAmount) > 0
                ? _c("span", [
                    _vm._v(
                      " 余额支付 ￥ " +
                        _vm._s(
                          parseFloat(_vm.orderList.balanceAmount).toFixed(2) ||
                            "--"
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.orderList.balanceAmount &&
              parseFloat(_vm.orderList.balanceAmount) > 0 &&
              parseFloat(_vm.orderList.totalPrice) -
                parseFloat(_vm.orderList.balanceAmount) >
                0
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.orderList.payTypeName) +
                        " ￥ " +
                        _vm._s(
                          (
                            parseFloat(_vm.orderList.totalPrice) -
                            parseFloat(_vm.orderList.balanceAmount)
                          ).toFixed(2)
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              parseFloat(_vm.orderList.balanceAmount) == 0
                ? _c("div", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.orderList.payTypeName) +
                          "￥" +
                          _vm._s(_vm.getPayedPrice)
                      ),
                    ]),
                    _vm.orderList.totalSubsidyAmount &&
                    parseFloat(_vm.orderList.totalSubsidyAmount) > 0
                      ? _c("span", [
                          _vm._v(
                            " + 代金券" +
                              _vm._s(_vm.orderList.totalSubsidyAmount)
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("付款时间：")]),
              _vm._v(_vm._s(_vm.orderList.payTime || "") + " "),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "commodity card" },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
            [
              _c("el-table-column", {
                attrs: { label: "商品信息", "min-width": "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "commodity_info" }, [
                          _c(
                            "div",
                            { staticClass: "img" },
                            [
                              _c("el-image", {
                                attrs: {
                                  src: scope.row.image || "",
                                  "preview-src-list": [scope.row.image || ""],
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "goodsInfo" }, [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(scope.row.name || "")),
                            ]),
                            _c("div", { staticClass: "factory" }, [
                              _vm._v(_vm._s(scope.row.factory || "")),
                            ]),
                          ]),
                        ]),
                        _vm._l(
                          scope.row.giftGoodsList,
                          function (giftItem, giftIndex) {
                            return _c(
                              "div",
                              { key: giftIndex, staticClass: "commodity_info" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "img" },
                                  [
                                    _c("el-image", {
                                      attrs: {
                                        src: giftItem.image || "",
                                        "preview-src-list": [
                                          giftItem.image || "",
                                        ],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "goodsInfo" },
                                  [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(_vm._s(giftItem.name || "")),
                                    ]),
                                    _c("div", { staticClass: "factory" }, [
                                      _vm._v(_vm._s(giftItem.factory || "")),
                                    ]),
                                    _c("GiftTag"),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "规格", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "commodity_info" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.specification || "") + " "
                          ),
                        ]),
                        _vm._l(
                          scope.row.giftGoodsList,
                          function (giftItem, giftIndex) {
                            return _c(
                              "div",
                              { key: giftIndex, staticClass: "commodity_info" },
                              [
                                _c("div", { staticClass: "specification" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(giftItem.specification || "") +
                                      " "
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "单价" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "commodity_info" }, [
                          _vm._v("￥" + _vm._s(scope.row.price || "")),
                        ]),
                        _vm._l(
                          scope.row.giftGoodsList,
                          function (giftItem, giftIndex) {
                            return _c(
                              "div",
                              { key: giftIndex, staticClass: "commodity_info" },
                              [
                                _c("div", { staticClass: "price" }, [
                                  _vm._v(
                                    "￥" + _vm._s(giftItem.salePrice || "")
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "需求量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "commodity_info" }, [
                          _vm._v(_vm._s(scope.row.qty || "0")),
                        ]),
                        _vm._l(
                          scope.row.giftGoodsList,
                          function (giftItem, giftIndex) {
                            return _c(
                              "div",
                              { key: giftIndex, staticClass: "commodity_info" },
                              [
                                _c("div", { staticClass: "qty" }, [
                                  _vm._v(_vm._s(giftItem.qty || "")),
                                ]),
                              ]
                            )
                          }
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "实发量",
                  prop: "shipmentsAmount",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "commodity_info" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.shipmentsAmount || "0") + " "
                          ),
                        ]),
                        _vm._l(
                          scope.row.giftGoodsList,
                          function (giftItem, giftIndex) {
                            return _c(
                              "div",
                              { key: giftIndex, staticClass: "commodity_info" },
                              [
                                _c("div", { staticClass: "qty" }, [
                                  _vm._v(_vm._s(giftItem.shipmentsAmount)),
                                ]),
                              ]
                            )
                          }
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "批号",
                  prop: "batchNumber",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "commodity_info" }, [
                          _vm._v(_vm._s(scope.row.batchNumber || "")),
                        ]),
                        _vm._l(
                          scope.row.giftGoodsList,
                          function (giftItem, giftIndex) {
                            return _c(
                              "div",
                              { key: giftIndex, staticClass: "commodity_info" },
                              [
                                _c("div", { staticClass: "batchNumber" }, [
                                  _vm._v(_vm._s(giftItem.batchNumber || "")),
                                ]),
                              ]
                            )
                          }
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "溯源码",
                  prop: "traceableCode",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "commodity_info" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.traceableCode || "") + " "
                          ),
                        ]),
                        _vm._l(
                          scope.row.giftGoodsList,
                          function (giftItem, giftIndex) {
                            return _c(
                              "div",
                              { key: giftIndex, staticClass: "commodity_info" },
                              [
                                _c("div", { staticClass: "traceableCode" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(giftItem.traceableCode || "") +
                                      " "
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "优惠", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "commodity_info",
                            staticStyle: {
                              "font-weight": "400",
                              color: "#f5222d",
                              "font-size": "16px",
                              "flex-direction": "column",
                              "align-items": "flex-start",
                              "justify-content": "center",
                            },
                          },
                          [
                            _vm._v(
                              " -￥" +
                                _vm._s(
                                  _vm.getSumSalePrice(
                                    scope.row.couponDiscount,
                                    scope.row.platformDiscount
                                  )
                                ) +
                                " "
                            ),
                            _c("order-discount-tooltip", {
                              staticStyle: { color: "#f5222d" },
                              attrs: {
                                "sum-discount-money":
                                  scope.row.couponDiscount || "0.00",
                                "sum-platform-discount-money":
                                  scope.row.platformDiscount || "0.00",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "小计", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "commodity_info",
                            staticStyle: {
                              "font-weight": "600",
                              color: "#f5222d",
                              "font-size": "16px",
                              "flex-direction": "column",
                              "align-items": "flex-start",
                              "justify-content": "center",
                            },
                          },
                          [_vm._v(" ￥" + _vm._s(scope.row.smallPrice) + " ")]
                        ),
                        _vm._l(
                          scope.row.giftGoodsList,
                          function (giftItem, giftIndex) {
                            return _c("div", {
                              key: giftIndex,
                              staticClass: "commodity_info",
                            })
                          }
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "price_detail" }, [
            _c("div", { staticClass: "items" }, [
              _c("span", { staticClass: "left" }, [_vm._v("商品件数：")]),
              _c("span", { staticClass: "right" }, [
                _vm._v(_vm._s(_vm.orderList.sumQty || 0) + "件"),
              ]),
            ]),
            _c("div", { staticClass: "items" }, [
              _c("span", { staticClass: "left" }, [_vm._v("商品总价：")]),
              _c("span", { staticClass: "right" }, [
                _vm._v("¥" + _vm._s(_vm.orderList.amount || 0)),
              ]),
            ]),
            _c("div", { staticClass: "items" }, [
              _c("span", { staticClass: "left" }, [_vm._v("运费：")]),
              _c("span", { staticClass: "right" }, [
                _vm._v("¥" + _vm._s(_vm.orderList.freightPrice || 0)),
              ]),
            ]),
            _c("div", { staticClass: "items" }, [
              _c("span", { staticClass: "left" }, [_vm._v("优惠：")]),
              _c("div", { staticClass: "right" }, [
                _c("span", [
                  _vm._v(
                    "-¥" +
                      _vm._s(
                        _vm.getSumSalePrice(
                          _vm.orderList.discountAmount,
                          _vm.orderList.platformDiscountAmount
                        )
                      )
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "items" }, [
              _c("span", { staticClass: "left" }, [_vm._v("应付总额：")]),
              _c("span", { staticClass: "right all" }, [
                _vm._v("¥" + _vm._s(_vm.orderList.totalPrice || 0)),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("CommentList", { attrs: { commentList: _vm.commentList } }),
      _c("QualiAftersale", {
        ref: "qualiAftersaleRef",
        attrs: { orderDetail: _vm.orderList },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "codeCont",
          attrs: {
            visible: _vm.isQrCode,
            width: "800px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.qrCodeBtn,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isQrCode = $event
            },
          },
        },
        [
          _c("div", { staticClass: "qrcodeCon" }, [
            _c("p", { staticClass: "qrTip" }, [
              _vm._v("请尽快付款！订单号：" + _vm._s(_vm.orderList.orderCode)),
            ]),
            _c("p", { staticClass: "qrdesc" }, [
              _vm._v("请您在5分钟内完成支付，否则订单会被自动取消！"),
            ]),
            _c("div", { staticClass: "qrCont" }, [
              _c("p", [
                _vm._v(" 应付金额： "),
                _c("span", [_vm._v("¥" + _vm._s(_vm.total))]),
              ]),
              _c("div", {
                ref: "qrcode",
                staticClass: "qrcodeImg",
                attrs: { id: "qrcode" },
              }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }