<!--  -->
<template>
  <div class="classification">
    <div ref="serchHead">
      <div class="conditions">
        <div class="real-condition">
          <!-- 未展开 -->
          <template v-if="!expanded">
            <div class="item big">
              <el-input
                v-model.trim="formRef.keyWord"
                size="small"
                placeholder="商品编码/ERP编码/商品名称/批准文号/生产厂家/sku编码"
              />
            </div>
          </template>
          <!-- 展开 -->
          <template v-else>
            <div class="item">
              <el-input
                v-model="formRef.goodsCode"
                size="small"
                placeholder="请输入商品编码"
              />
            </div>
            <div class="item">
              <el-input
                v-model="formRef.medicineCode"
                size="small"
                placeholder="请输入商品ERP编码"
              />
            </div>
            <div class="item">
              <el-input
                v-model="formRef.goodsName"
                size="small"
                placeholder="请输入商品名称"
              />
            </div>
            <div class="item">
              <el-input
                v-model="formRef.batchNumber"
                size="small"
                placeholder="请输入产品批号"
              />
            </div>
            <div class="item">
              <el-input
                v-model="formRef.factory"
                size="small"
                placeholder="请输入生产厂家"
              />
            </div>
            <div class="item">
              <el-select
                v-model="formRef.areaId"
                size="small"
                placeholder="请选择区域"
                clearable
              >
                <el-option
                  v-for="item in regionList"
                  :key="item.areaId"
                  :label="item.schemeName"
                  :value="item.areaId"
                />
              </el-select>
            </div>
            <div class="item">
              <el-select
                v-model="formRef.shopTypeCode"
                size="small"
                placeholder="请选择客户类型"
                clearable
              >
                <el-option
                  v-for="item in shopTypeList"
                  :key="item.typeCode"
                  :label="item.typeName"
                  :value="item.typeCode"
                />
              </el-select>
            </div>
            <div class="item">
              <el-select v-model="formRef.type" size="small" placeholder="">
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="item">
              <el-select
                v-model="formRef.groupId"
                size="small"
                :placeholder="`${
                  '请选择' + (formRef.type === 1 ? '白名单' : '黑名单')
                }`"
                clearable
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.schemeName"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div class="item">
              <el-select
                v-model="formRef.activityType"
                size="small"
                placeholder="请选择活动类型"
                clearable
                @change="changeOneClassId"
              >
                <el-option
                  v-for="item in activityTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="item">
              <el-input
                v-model="formRef.skuCode"
                size="small"
                placeholder="请输入SKU编码"
              />
            </div>
          </template>
          <!-- 条件操作按钮 -->
          <div class="item op">
            <el-button size="small" @click="resetForm">重置</el-button>
            <el-button type="primary" size="small" @click="getTableList(1)">
              搜索
            </el-button>
            <el-button type="text" size="small" @click="handleExpand()">
              {{ expanded ? "收起" : "展开"
              }}<i
                :class="{
                  'el-icon-arrow-up': expanded,
                  'el-icon-arrow-down': !expanded,
                  'el-icon--right': true,
                }"
              ></i>
            </el-button>
          </div>
        </div>
      </div>
      <div class="header">
        <span class="font">商品列表</span>
        <div>
          <el-button
            size="small"
            plain
            @click="doUpdateSkuStatusList('PUTAWAY')"
            v-if="activeName == 'MERCHANT_REMOVAL'"
          >
            批量上架
          </el-button>
          <el-button
            size="small"
            plain
            @click="doUpdateSkuStatusList('SOLDOUT')"
            v-if="activeName == 'ON_SALE'"
          >
            批量下架
          </el-button>
          <el-button size="small" plain @click="doExportSkuExcel">
            导出商品
          </el-button>
          <el-button size="small" plain @click="handleMoreGift">
            批量送赠品
          </el-button>
          <el-button size="small" type="primary" @click="handlePublic">
            发布商品
          </el-button>
          <el-button size="small" type="primary" @click="handleMoreSend">
            批量匹配商品
          </el-button>
        </div>
      </div>
    </div>

    <div class="conditions">
      <Tabs
        :tabsList="tabsList"
        :activeName.sync="activeName"
        @tab-click="handleChange"
      />
    </div>
    <div
      class="center"
      style="position: relative"
      :style="contentHeight ? `height:${contentHeight}px!important` : ''"
    >
      <Table
        :tableData="tableData"
        :activeName="activeName"
        :selectionList="selectionList"
        :page="page"
        :selectionOpt="true"
        @getList="getList"
        @handleSelectionChange="handleSelectionChange"
        @detailHandle="detailHandle"
        @listing="listing"
        @delist="delist"
        @editHandle="editHandle"
        @fullGift="fullGift"
        @addStash="addStash"
        @logs="logs"
        @editPrice="handleEditPrice"
        @editStock="handleEditStock"
        :shopTypeList="shopTypeList"
        style="width: 100%; height: 100%"
      ></Table>
    </div>

    <EditPriceDlg ref="refEditPriceDlg" @updateSuccess="handleChange" />
    <EditStockDlg ref="refEditStockDlg" @updateSuccess="handleChange" />
    <EditLogs ref="refEditLogsDlg" @didClose="didCloseLogs" />
  </div>
</template>

<script>
// import MyTable from '@/components/myTable'
import Table from "./table.vue";
import Tabs from "@/components/Tabs";
import { goodsclassOne, goodsclassTwo } from "@/api/commodityreference";
import {
  skuPage,
  skuStatus,
  checkMerchantRegisterKB,
} from "@/api/salesControl/commodity";
import avater from "/public/img/default.png";
import { Message } from "element-ui";
import _ from "lodash";

import {
  skuUpdate,
  skuAdd,
  skuId,
  groupInfos,
  salesgroupinfo,
} from "@/api/salesControl/commodity";
import {
  getunitList,
  getEnableListByType,
} from "@/api/productManage/auditProduct";
import { updateSkuStatusList, exportSkuExcel } from "@/api/goods";

import EditPriceDlg from "./dialog/EditPriceDlg.vue";
import EditStockDlg from "./dialog/EditStockDlg.vue";
import EditLogs from "./dialog/EditLogs.vue";
import { mapGetters } from "vuex";
export default {
  name: "WareProduct",
  components: { Tabs, Table, EditPriceDlg, EditStockDlg, EditLogs },
  data() {
    return {
      contentHeight: 0,
      expanded: false, //搜索条件是否展开
      activeName: "ON_SALE",
      tabsList: [
        // tabs 传的值
        {
          name: "ON_SALE",
          label: "销售中",
          value: 0,
        },
        {
          name: "MERCHANT_REMOVAL",
          label: "商家下架",
          value: 0,
        },
        {
          name: "PLATFORM_REMOVAL",
          label: "平台下架",
          value: 0,
        },
        {
          name: "BASE_AWAIT",
          label: "基库待审核",
          value: 0,
        },
        {
          name: "BASE_ALREADY",
          label: "审核通过",
          value: 0,
        },
        {
          name: "SKU_AWAIT",
          label: "上架待审核",
          value: 0,
        },
        {
          name: "SKU_FAIL",
          label: "商品审核未通过",
          value: 0,
        },
        {
          name: "BASE_TEMPORARY_FAIL",
          label: "基库审核未通过",
          value: 0,
        },
        {
          name: "UNPRICED",
          label: "未标价",
          value: 0,
        },
        {
          name: "SOLD_OUT",
          label: "缺货下架",
          value: 0,
        },
      ],
      goodsOneClassIdOptions: [],
      goodsTwoClassIdOptions: [],
      goodsThreeClassIdOptions: [],

      activityTypeList: [
        {
          label: "一口价",
          value: 1,
        },
        {
          label: "特价",
          value: 2,
        },
        {
          label: "单品包邮",
          value: 3,
        },
        {
          label: "赠品",
          value: 4,
        },
      ],
      shopTypeList: [], //可选客户类型
      regionList: [],
      typeList: [
        { label: "设置区域、客户类型及黑名单", value: 0 },
        { label: "白名单", value: 1 },
      ],
      userList: [],

      avater: avater,
      formRef: {
        keyWord: "", // 多合一关键字
        batchNumber: "", //生产批号
        goodsCode: "", //商品编码
        medicineCode: "", //商品erp编码
        goodsName: "", //商品名称
        factory: "", //生产厂家

        areaId: "", //区域
        shopTypeCode: "", //  客户类型：
        type: 0, //黑白名单
        activityType: "", //活动类型
        skuCode: "", //sku编码
      },
      formTool: {
        goodsOneClassId: "",
        goodsTwoClassId: "",
        goodsThreeClassId: "",
      },
      selectionList: [],
      operation: true,
      selection: true,
      index: true,
      tableData: [],
      tableOption: [
        { label: "商品信息", prop: "image", slot: true, minWidth: "200px" }, // 这里表示自定义列
        // { label: '商品名称', prop: 'name' },
        // { label: '生产厂商', prop: 'factory' },
        { label: "批准文号", prop: "approvalNumber", width: "260px" },
        { label: "商品属性", prop: "type" },
        { label: "ERP编码", prop: "medicineCode" },
        { label: "发布时间", prop: "createTime", width: "180px" },
      ],
      page: {
        total: 0,
        current: 1,
        size: 20,
        pageSizes: [10, 20, 30, 50],
      },
      isRegisterKB: true, //是否注册康贝
      isRecord: false, //离开页面是否记录当前输入框的值
      recoderKey: "salesControl-commodity-list",
    };
  },
  computed: {},
  watch: {},
  async created() {
    // 获取输入框的纪录值
    await this.getInputRecoderValue();
    if (this.$store.state.tabStatus.goodsTab) {
      this.activeName = this.$store.state.tabStatus.goodsTab;
      this.handleChange(this.activeName);
    } else {
      this.activeName = this.$route.query.tab
        ? this.$route.query.tab
        : "ON_SALE";
      this.getTableList();
    }
    this.query();
    // this.getWarebase()
    this.doLoadArea();
    this.salesgroupinfo();
    this.getEnableListByType();
  },
  mounted() {
    window.addEventListener("keypress", this.enterSearch);
  },
  beforeCreate() {},
  beforeMount() {
    this.$store.dispatch("publicGoods/setStep", 1);
  },
  beforeUpdate() {},
  updated() {
    this.getWindowView();
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.enterSearch);
    if (!this.isRecord) {
      this.$store.dispatch("pageRecorder/clearPageRecord");
    }
  },
  destroyed() {},
  activated() {},
  methods: {
    //回车键执行搜索
    enterSearch(e) {
      if (e.keyCode == 13) {
        this.getTableList(1);
      }
    },
    checkMerchantRegisterKB() {
      checkMerchantRegisterKB()
        .then((res) => {
          if (res.status) {
            this.$store.dispatch("publicGoods/setStep", 1);
            this.$router.push("/productManage/salesControl-publish-list");
          } else {
            this.$message.warning(res.message);
          }
        })
        .catch((err) => {});
    },
    getWindowView() {
      let innerHeight = window.innerHeight; //获取页面高度
      let headHeight = 0;
      this.$nextTick(() => {
        headHeight = this.$refs.serchHead.clientHeight; //获取head 高度
        let contentHeight = innerHeight - 300 - headHeight;
        this.headHeight = headHeight;
        this.contentHeight = contentHeight;
      });
    },
    doUpdateSkuStatusList(status) {
      if (!this.selectionList && this.selectionList.length == 0) {
        this.$message.warning("请选择商品");
        return;
      }
      let skuIds = this.selectionList.map((item) => {
        return item.skuId;
      });
      const data = {
        skuIds: skuIds,
        status: status,
      };
      updateSkuStatusList(data).then((res) => {
        if (res) {
          this.$message.success("处理成功");
          this.getTableList(1);
        }
      });
    },
    doExportSkuExcel() {
      let current = this.page.current;
      let params = this.getCondition(current);
      exportSkuExcel(params).then((response) => {
        const blob = response.data;
        if (blob && blob.size === 0) {
          Message.warning("内容为空，无法下载");
          return;
        }
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        const contentDisposition = response.headers["content-disposition"];
        const fileName = decodeURIComponent(
          contentDisposition.substring(contentDisposition.indexOf("=") + 1)
        );
        console.log(fileName);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        window.setTimeout(function () {
          URL.revokeObjectURL(blob);
          document.body.removeChild(link);
          Message.success("导出成功");
        }, 0);
      });
    },
    // 获取类型
    getEnableListByType() {
      let params = {
        type: 0,
      };
      getEnableListByType(params).then((res) => {
        console.log(res, "getEnableListByType");
        if (res.code == 0) {
          this.shopTypeList = res.data;
        }
      });
    },

    doLoadArea() {
      groupInfos().then((data) => {
        if (data) {
          this.regionList = data;
        }
      });
    },
    // 查询用户组信息
    salesgroupinfo() {
      let type = 1;
      if (this.formRef.type == 1) {
        type = 1;
      }

      let parmas = {};
      parmas.type = type;
      salesgroupinfo(parmas).then((res) => {
        this.userList = res;
      });
    },
    handlePublic() {
      this.recordInput();
      this.checkMerchantRegisterKB();
    },
    handleMoreSend() {
      this.recordInput();
      checkMerchantRegisterKB()
        .then((res) => {
          if (res.status) {
            this.$router.push("/productManage/release-release");
          } else {
            this.$message.warning(res.message);
          }
        })
        .catch((err) => {});
    },
    handleMoreGift() {
      // var arr = [];
      let goodsIdStr = "";
      let skuIdStr = "";
      let flag = false;
      for (const item of this.selectionList) {
        const obj = {
          goodsId: item.goodsId,
          goodsSkuId: item.skuId,
        };
        // arr.push(obj);
        goodsIdStr += item.goodsId + ",";
        skuIdStr += item.skuId + ",";

        if (item.activityType == 4) {
          this.$message.warning(
            "赠品不能赠送赠品，请您检查批量设置的对象是否包含赠品!"
          );
          flag = true;
          return false;
        }
      }

      if (flag) return;
      if (!goodsIdStr) {
        this.$message.warning("请先选择商品！");
      } else {
        goodsIdStr = goodsIdStr.slice(0, goodsIdStr.length - 1);
        skuIdStr = skuIdStr.slice(0, skuIdStr.length - 1);
        this.$router.push(
          "./salesControl-fullGift-createGift?goodsSkuId=" +
            skuIdStr +
            "&goodsId=" +
            goodsIdStr
        );
      }
    },
    handleEditPrice(index) {
      const item = this.tableData[index];
      this.$refs.refEditPriceDlg.open(item);
    },
    handleEditStock(index) {
      const item = this.tableData[index];
      this.$refs.refEditStockDlg.open(item);
    },
    handleExpand() {
      this.expanded = !this.expanded;
    },
    //切换tabs按钮
    handleChange() {
      this.$store.dispatch("tabStatus/setGoodsTab", this.activeName);
      this.getTableList(1);
    },
    // 批量下架
    clickBtn() {
      if (this.selectionList.length == 0) {
        this.$message.warning("请先选择要下架的数据");
      } else {
        var arr = [];
        this.selectionList.forEach((item) => {
          const obj = {
            goodsId: item.goodsId,
          };
          arr.push(obj);
        });
        this.batchPutaway(arr);
      }
    },
    // 上架接口
    batchPutaway(params) {
      putaway(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("批量上架成功");
          this.resetForm();
          this.getTableList(1);
        } else {
          this.$message.error("批量上架失败");
        }
      });
    },
    // 重置查询条件
    resetForm() {
      this.page.current = 1;
      this.page.size = 10;
      //this.$refs.formRef.resetFields();
      for (let key in this.formRef) {
        this.formRef[key] = "";
      }
      this.formRef.type = 0;
      this.formTool.goodsOneClassId = "";
      this.formTool.goodsTwoClassId = "";
      this.formTool.goodsThreeClassId = "";
    },
    // 单条上架
    deleteHandle(row) {
      const params = [
        {
          goodsId: row.goodsId,
        },
      ];
      putaway(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("上架成功");
          this.resetForm();
          this.getTableList(1);
        } else {
          this.$message.error("上架失败");
        }
      });
    },
    // 切换分页
    getList(page) {
      this.page.current = page.current;
      this.page.size = page.size;
      this.getTableList();
    },
    // 勾选
    handleSelectionChange(val) {
      this.selectionList = val;
      console.log(val);
    },
    getCondition(current) {
      if (current) {
        // 搜索条件改变从第一页开始查询
        this.page.current = current;
      }
      let params = {
        // ...this.formRef,
        // ...this.formTool,
        status: this.activeName,
        current: this.page.current,
        size: this.page.size,
      };
      if (this.expanded) {
        _.merge(params, this.formRef);
        params.keyWord = "";
      } else {
        params.keyWord = this.formRef.keyWord;
      }
      return params;
    },
    // 查询
    getTableList(current) {
      let params = this.getCondition(current);
      skuPage(params).then((res) => {
        if (res) {
          this.tableData = res.page.records;
          this.page.total = res.page.total;
          this.tabsList[0].value = res.staticVo.onSaleCount;
          this.tabsList[1].value = res.staticVo.merchantRemovalCount;
          this.tabsList[2].value = res.staticVo.sySoldOutCount;
          this.tabsList[3].value = res.staticVo.baseAwaitCount;
          this.tabsList[5].value = res.staticVo.skuAwaitCount;
          this.tabsList[6].value = res.staticVo.skuFailCount;
          this.tabsList[7].value = res.staticVo.baseTemporaryFailCount;

          this.tabsList[8].value = res.staticVo.unpriceCount; //未标价
          this.tabsList[9].value = res.staticVo.soldOutCount; //缺货下架
        } else {
          this.tableData = [];
          this.page.total = 0;
        }
      });
    },
    // 日志
    logs(val) {
      this.$refs.refEditLogsDlg.handleOpen(val);
    },
    didCloseLogs() {
      this.$refs.refEditLogsDlg.closeDlg();
    },
    // 单品送赠品。纪录输入框的值
    fullGift() {
      //  离开页面纪录输入框的值
      this.recordInput();
    },
    // 编辑
    editHandle(val) {
      //  离开页面纪录输入框的值
      this.recordInput();
      if (this.activeName == "BASE_TEMPORARY_FAIL") {
        this.$router.push({
          path: "/productManage/goodsManage-edit-details",
          query: {
            activeName: this.activeName,
            goodsTemporaryId: val.goodsTemporaryId,
            goodsId: val.goodsId,
            skuId: val.skuId,
            type: "edit",
            sourcePage: "/productManage/salesControl-commodity-list",
          },
        });
      } else {
        this.$router.push({
          path: "/productManage/salesControl-publish-detail",
          query: {
            goodsId: val.goodsId,
            skuId: val.skuId,
            fromPage: "edit",
          },
        });
      }
    },
    // 详情
    detailHandle(val) {
      //  离开页面纪录输入框的值
      this.recordInput();
      if (
        this.activeName == "BASE_TEMPORARY_FAIL" ||
        this.activeName == "BASE_AWAIT"
      ) {
        this.$router.push({
          path: "/productManage/goodsManage-edit-details",
          query: {
            activeName: this.activeName,
            goodsTemporaryId: val.goodsTemporaryId,
            goodsId: val.goodsId,
            skuId: val.skuId,
            sourcePage: "/productManage/salesControl-commodity-list",
          },
        });
      } else {
        this.$router.push({
          path: "/productManage/salesControl-publish-detail",
          query: {
            goodsId: val.goodsId,
            skuId: val.skuId,
            fromPage: "detail",
          },
        });
      }
    },
    // 上架
    listing(item) {
      let params = {
        id: item.skuId,
        status: "PUTAWAY",
      };
      skuStatus(params).then((res) => {
        if (res) {
          this.$message.success("上架成功");
          this.getTableList();
        } else {
          this.$message.success(res.msg);
        }
      });
    },
    // 下架
    delist(item) {
      let params = {
        id: item.skuId,
        status: "SOLDOUT",
      };
      skuStatus(params).then((res) => {
        if (res) {
          this.$message.success("下架成功");
          this.getTableList();
        } else {
          this.$message.success("下架失败");
        }
      });
    },
    // 加入仓库
    addStash(val) {
      console.log(val);
      this.$router.push({
        path: "/productManage/publishProduct-addGoodsToStore",
        query: {
          activeName: this.activeName,
          id: val.goodsId,
          type: "add",
        },
      });
    },
    // 搜索
    // clickSearch (current) {
    //   this.getWarebase(current)
    // },
    // 初次进来只查询一级分类 不联动
    query() {
      goodsclassOne()
        .then((res) => {
          if (res.code == 0) {
            this.goodsOneClassIdOptions = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换一级分类
    changeOneClassId(val) {
      if (val) {
        this.goodsclassTwo(val);
      } else {
        this.formTool.goodsTwoClassId = "";
        this.goodsTwoClassIdOptions = [];
        this.formTool.goodsThreeClassId = "";
        this.goodsThreeClassIdOptions = [];
      }
    },
    // 切换二级分类
    changeTwoClassId(val) {
      if (val) {
        this.goodsclassThree(val);
      } else {
        this.formTool.goodsThreeClassId = "";
        this.goodsThreeClassIdOptions = [];
      }
    },
    // 查询一级分类
    goodsclassOne() {
      goodsclassOne()
        .then((res) => {
          if (res.code == 0) {
            this.goodsOneClassIdOptions = res.data;
            if (this.goodsOneClassIdOptions.length > 0) {
              this.formTool.goodsOneClassId = this.goodsOneClassIdOptions[0].id;
              // this.goodsclassTwo(this.goodsOneClassIdOptions[0].id)
            } else {
              this.formTool.goodsOneClassId = "";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询二级分类
    goodsclassTwo(id) {
      goodsclassTwo(id)
        .then((res) => {
          if (res.code == 0) {
            this.goodsTwoClassIdOptions = res.data;
            if (this.goodsTwoClassIdOptions.length > 0) {
              this.formTool.goodsTwoClassId = "";
              this.formTool.goodsThreeClassId = "";
              this.goodsThreeClassIdOptions = [];
              // this.goodsclassThree(this.goodsTwoClassIdOptions[0].id)
            } else {
              this.formTool.goodsTwoClassId = "";
              this.formTool.goodsThreeClassId = "";
              this.goodsThreeClassIdOptions = [];
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询三级分类
    goodsclassThree(id) {
      goodsclassTwo(id)
        .then((res) => {
          if (res.code == 0) {
            this.goodsThreeClassIdOptions = res.data;
            if (this.goodsThreeClassIdOptions.length > 0) {
              this.formTool.goodsThreeClassId = "";
            } else {
              this.formTool.goodsThreeClassId = "";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 记录输入框的值
    recordInput() {
      this.isRecord = true;
      this.$store.dispatch("pageRecorder/addPageRecord", {
        name: this.recoderKey,
        value: { ...this.formRef, expanded: this.expanded },
      });
    },
    // 获取页面记录
    getInputRecoderValue() {
      return new Promise((resolve) => {
        this.$store
          .dispatch("pageRecorder/getRecoderByName", this.recoderKey)
          .then((res) => {
            if (res) {
              this.formRef = res;
              this.expanded = res.expanded || false;
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.classification {
  padding: 0px;
}

.real-condition {
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
  margin-left: -8px;
  justify-content: space-between;

  .item {
    width: calc(25% - 8px);
    margin-left: 8px;
    margin-top: 8px;

    ::v-deep .el-select {
      width: 100%;
    }

    &.big {
      width: 45%;
    }

    &.op {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px !important;
  border-bottom: 1px solid #f5f5f5;

  .font {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    text-align: left;
    color: #262626;
  }

  .el-form-item {
    margin: 0px 0px 0px 8px !important;
  }
}

.conditions {
  padding: 0 24px 0 24px;
}

.center {
  margin-top: 16px;
  padding: 0 24px 0 24px;
  height: calc(100% - 350px);

  ::v-deep .imageP {
    // width: calc(100% - 110px);
    p {
      margin: 0 0 0 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
</style>
