var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c("div", { ref: "serchHead" }, [
        _c("div", { staticClass: "conditions" }, [
          _c(
            "div",
            { staticClass: "real-condition" },
            [
              !_vm.expanded
                ? [
                    _c(
                      "div",
                      { staticClass: "item big" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder:
                              "商品编码/ERP编码/商品名称/批准文号/生产厂家/sku编码",
                          },
                          model: {
                            value: _vm.formRef.keyWord,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formRef,
                                "keyWord",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formRef.keyWord",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入商品编码",
                          },
                          model: {
                            value: _vm.formRef.goodsCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formRef, "goodsCode", $$v)
                            },
                            expression: "formRef.goodsCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入商品ERP编码",
                          },
                          model: {
                            value: _vm.formRef.medicineCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formRef, "medicineCode", $$v)
                            },
                            expression: "formRef.medicineCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入商品名称",
                          },
                          model: {
                            value: _vm.formRef.goodsName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formRef, "goodsName", $$v)
                            },
                            expression: "formRef.goodsName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入产品批号",
                          },
                          model: {
                            value: _vm.formRef.batchNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.formRef, "batchNumber", $$v)
                            },
                            expression: "formRef.batchNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入生产厂家",
                          },
                          model: {
                            value: _vm.formRef.factory,
                            callback: function ($$v) {
                              _vm.$set(_vm.formRef, "factory", $$v)
                            },
                            expression: "formRef.factory",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              placeholder: "请选择区域",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formRef.areaId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formRef, "areaId", $$v)
                              },
                              expression: "formRef.areaId",
                            },
                          },
                          _vm._l(_vm.regionList, function (item) {
                            return _c("el-option", {
                              key: item.areaId,
                              attrs: {
                                label: item.schemeName,
                                value: item.areaId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              placeholder: "请选择客户类型",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formRef.shopTypeCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.formRef, "shopTypeCode", $$v)
                              },
                              expression: "formRef.shopTypeCode",
                            },
                          },
                          _vm._l(_vm.shopTypeList, function (item) {
                            return _c("el-option", {
                              key: item.typeCode,
                              attrs: {
                                label: item.typeName,
                                value: item.typeCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "small", placeholder: "" },
                            model: {
                              value: _vm.formRef.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.formRef, "type", $$v)
                              },
                              expression: "formRef.type",
                            },
                          },
                          _vm._l(_vm.typeList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              placeholder: `${
                                "请选择" +
                                (_vm.formRef.type === 1 ? "白名单" : "黑名单")
                              }`,
                              clearable: "",
                            },
                            model: {
                              value: _vm.formRef.groupId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formRef, "groupId", $$v)
                              },
                              expression: "formRef.groupId",
                            },
                          },
                          _vm._l(_vm.userList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.schemeName, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              placeholder: "请选择活动类型",
                              clearable: "",
                            },
                            on: { change: _vm.changeOneClassId },
                            model: {
                              value: _vm.formRef.activityType,
                              callback: function ($$v) {
                                _vm.$set(_vm.formRef, "activityType", $$v)
                              },
                              expression: "formRef.activityType",
                            },
                          },
                          _vm._l(_vm.activityTypeList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入SKU编码",
                          },
                          model: {
                            value: _vm.formRef.skuCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formRef, "skuCode", $$v)
                            },
                            expression: "formRef.skuCode",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
              _c(
                "div",
                { staticClass: "item op" },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.resetForm } },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.getTableList(1)
                        },
                      },
                    },
                    [_vm._v(" 搜索 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleExpand()
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.expanded ? "收起" : "展开")),
                      _c("i", {
                        class: {
                          "el-icon-arrow-up": _vm.expanded,
                          "el-icon-arrow-down": !_vm.expanded,
                          "el-icon--right": true,
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "header" }, [
          _c("span", { staticClass: "font" }, [_vm._v("商品列表")]),
          _c(
            "div",
            [
              _vm.activeName == "MERCHANT_REMOVAL"
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.doUpdateSkuStatusList("PUTAWAY")
                        },
                      },
                    },
                    [_vm._v(" 批量上架 ")]
                  )
                : _vm._e(),
              _vm.activeName == "ON_SALE"
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.doUpdateSkuStatusList("SOLDOUT")
                        },
                      },
                    },
                    [_vm._v(" 批量下架 ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { size: "small", plain: "" },
                  on: { click: _vm.doExportSkuExcel },
                },
                [_vm._v(" 导出商品 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", plain: "" },
                  on: { click: _vm.handleMoreGift },
                },
                [_vm._v(" 批量送赠品 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handlePublic },
                },
                [_vm._v(" 发布商品 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleMoreSend },
                },
                [_vm._v(" 批量匹配商品 ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "conditions" },
        [
          _c("Tabs", {
            attrs: { tabsList: _vm.tabsList, activeName: _vm.activeName },
            on: {
              "update:activeName": function ($event) {
                _vm.activeName = $event
              },
              "update:active-name": function ($event) {
                _vm.activeName = $event
              },
              "tab-click": _vm.handleChange,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "center",
          staticStyle: { position: "relative" },
          style: _vm.contentHeight
            ? `height:${_vm.contentHeight}px!important`
            : "",
        },
        [
          _c("Table", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: {
              tableData: _vm.tableData,
              activeName: _vm.activeName,
              selectionList: _vm.selectionList,
              page: _vm.page,
              selectionOpt: true,
              shopTypeList: _vm.shopTypeList,
            },
            on: {
              getList: _vm.getList,
              handleSelectionChange: _vm.handleSelectionChange,
              detailHandle: _vm.detailHandle,
              listing: _vm.listing,
              delist: _vm.delist,
              editHandle: _vm.editHandle,
              fullGift: _vm.fullGift,
              addStash: _vm.addStash,
              logs: _vm.logs,
              editPrice: _vm.handleEditPrice,
              editStock: _vm.handleEditStock,
            },
          }),
        ],
        1
      ),
      _c("EditPriceDlg", {
        ref: "refEditPriceDlg",
        on: { updateSuccess: _vm.handleChange },
      }),
      _c("EditStockDlg", {
        ref: "refEditStockDlg",
        on: { updateSuccess: _vm.handleChange },
      }),
      _c("EditLogs", {
        ref: "refEditLogsDlg",
        on: { didClose: _vm.didCloseLogs },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }